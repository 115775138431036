import React, { useState, useEffect, useRef } from "react";
import { Link, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";

import usaFlag from "./img/usa.webp";
import brFlag from "./img/br.webp";

function Navigation() {
  const { i18n, t } = useTranslation();
  const location = useLocation();
  const [activePage, setActivePage] = useState("home");
  const navbarToggler = useRef(null);
  const navbarCollapse = useRef();
  const logoBox = useRef(null);
  const currentLanguage = i18n.language;
  const navbarItems = t('Home.Navbar', { returnObjects: true });

  const changeLanguage = () => {
    const newLanguage = currentLanguage === "pt" ? "en" : "pt";
    i18n.changeLanguage(newLanguage);
    localStorage.setItem("language", newLanguage);
  };

  useEffect(() => {
    const savedLanguage = localStorage.getItem("language");
    if (savedLanguage) {
      i18n.changeLanguage(savedLanguage);
    }
  }, [i18n]);

  useEffect(() => {
    const currentPath = location.pathname.substring(1);
    setActivePage(
      currentPath === "" || currentPath === "category" ? "home" : currentPath
    );
  }, [location]);

  const handleTouchStart = () => {
    navbarToggler.current.classList.add("hover");
  };

  const handleTouchEnd = () => {
    setTimeout(() => {
      navbarToggler.current.classList.remove("hover");
    }, 250);
  };

  useEffect(() => {
    const handleClassChange = () => {
      if (navbarCollapse.current.classList.contains("show")) {
        logoBox.current.classList.add("collapse");
      } else {
        logoBox.current.classList.remove("collapse");
      }
    };

    const observer = new MutationObserver(handleClassChange);
    observer.observe(navbarCollapse.current, {
      attributes: true,
      attributeFilter: ["class"],
    });

    handleClassChange();

    return () => {
      observer.disconnect();
    };
  }, []);

  return (
    <nav className="navbar navbar-expand-lg navbar-custom col-lg-2">
      <div className="container-fluid">
        <Link
          className="navbar-brand col-12 logo-box fs-3"
          to="/"
          ref={logoBox}
        >
          <img src="/logo.png" alt="Logo do menu Onomatopeias" />
        </Link>
        <button
          className="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarNav"
          aria-controls="navbarNav"
          aria-expanded="false"
          aria-label="Toggle navigation"
          ref={navbarToggler}
          onTouchStart={handleTouchStart}
          onTouchEnd={handleTouchEnd}
          onMouseEnter={handleTouchStart}
          onMouseLeave={handleTouchEnd}
        >
          <span className="navbar-toggler-icon"></span>
        </button>
        <div
          className="collapse navbar-collapse"
          id="navbarNav"
          ref={navbarCollapse}
        >
          <ul className="navbar-nav">
            {Object.keys(navbarItems).map((key) => (
              <li className="nav-item" key={key}>
                <Link
                  className={`nav-link ${activePage === key ? "active" : ""}`}
                  to={key === "home" ? "/" : `/${key}`} // Cria o path baseado na chave
                >
                  {navbarItems[key]}
                </Link>
              </li>
            ))}
          </ul>
        </div>
        <div onClick={changeLanguage} className="lang">
          <img
            src={currentLanguage === "pt" ? usaFlag : brFlag}
            alt={currentLanguage === "pt" ? "English" : "Português"}
            style={{ width: "30px" }}
          />
        </div>
      </div>
    </nav>
  );
}

export default Navigation;
