import React, { useState, useEffect } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

import Navigation from "./components/index/Navigation";
import Home from "./pages/Home";
import About from "./pages/About";
import Tags from "./pages/Tags";
import Category from "./pages/Category.js";
import Onomatopeias from "./pages/Onomatopeias";


function App() {
  const [showH1, setShowH1] = useState(true);

  useEffect(() => {
    const handleResize = () => {
      const larguraDaJanela = window.innerWidth;

      if (larguraDaJanela < 590 && showH1) {
        setShowH1(false);
      } else if (larguraDaJanela >= 590 && !showH1) {
        setShowH1(true);
      }
    };
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [showH1]);

  return (
    <Router>
      <div className="container-fluid">
        <div className="row">
          <Navigation />
          <Routes>
            <Route
              exact
              path="/"
              element={
                <React.Fragment>
                  <Home />
                </React.Fragment>
              }
            />
            <Route
              exact
              path="/about"
              element={
                <React.Fragment>
                  <About />
                </React.Fragment>
              }
            />
            <Route
              exact
              path="/tags"
              element={
                <React.Fragment>
                  <Tags />
                </React.Fragment>
              }
            />
            <Route
              exact
              path="/category"
              element={
                <React.Fragment>
                  <Category />
                </React.Fragment>
              }
            />
            <Route
              exact
              path="/tags/:tag"
              element={
                <React.Fragment>
                  <Onomatopeias />
                </React.Fragment>
              }
            />
          </Routes>
        </div>
      </div>
    </Router>
  );
}

export default App;
