import { useTranslation } from "react-i18next";
import { animated } from "react-spring";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPaw,
  faHandHoldingHeart,
  faCloudBolt,
  faAppleWhole,
  faPodcast,
  faPersonFallingBurst,
  faHandsBubbles,
  faMicrochip,
  faCrosshairs,
  faExplosion,
} from "@fortawesome/free-solid-svg-icons";

import useCustomInView from "../index/useCustomInView";

function Categories() {
  const [category, categoryStyle] = useCustomInView("", 500);
  const navigate = useNavigate();

  const handleCategoryClick = (category) => {
    navigate("/category", { state: { category } });
  };
  const { t } = useTranslation();

  const Categories = [
    {
      key: "animais",
      icon: faPaw,
      color: "rgb(255, 156, 102)",
      label: t("Home.Categories.animals"),
    },
    {
      key: "impactos",
      icon: faCrosshairs,
      color: "#ffffff",
      label: t("Home.Categories.impactos"),
    },
    {
      key: "clima",
      icon: faCloudBolt,
      color: "#fffff",
      label: t("Home.Categories.climate"),
    },
    {
      key: "ação",
      icon: faExplosion,
      color: null,
      label: t("Home.Categories.action"),
    },
    {
      key: "sons",
      icon: faPodcast,
      color: null,
      label: t("Home.Categories.sounds"),
    },
    {
      key: "estado humano",
      icon: faPersonFallingBurst,
      color: "rgb(0, 255, 255)",
      label: t("Home.Categories.human_state"),
    },
    {
      key: "toque",
      icon: faHandsBubbles,
      color: "#ffffff",
      label: t("Home.Categories.touch"),
    },
    {
      key: "eletrônico",
      icon: faMicrochip,
      color: "rgb(232, 232, 232)",
      label: t("Home.Categories.electronic"),
    },
    {
      key: "emoção",
      icon: faHandHoldingHeart,
      color: null,
      label: t("Home.Categories.emotion"),
    },
    {
      key: "comer beber",
      icon: faAppleWhole,
      color: null,
      label: t("Home.Categories.food_drink"),
    },
  ];

  return (
    <animated.div
      className="category-row row gap-2"
      style={categoryStyle}
      ref={category}
    >
      {Categories.map((category) => (
        <div
          className="category-block"
          key={category.key}
          onClick={() => handleCategoryClick(category.key)}
        >
          <div className="text-block">
            <FontAwesomeIcon
              icon={category.icon}
              style={{ color: category.color || "inherit" }}
            />
            {category.label}
          </div>
        </div>
      ))}
    </animated.div>
  );
}

export default Categories;
