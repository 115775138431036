import { useInView } from "react-intersection-observer";
import { useSpring } from "@react-spring/web";

function useCustomInView($type, $delay = 0, $useInView = true) {
  const { ref, inView } = useInView({
    triggerOnce: true,
    rootMargin: "-10% 0%",
  });

  let animationConfig;

  switch ($type) {
    case "left-right":
      animationConfig = {
        from: { opacity: 0, x: -200 },
        to: { opacity: 1, x: 0 },
        config: { duration: 350 },
        delay: $delay,
      };
      break;
    case "right-left":
      animationConfig = {
        from: { opacity: 0, x: 200 },
        to: { opacity: 1, x: 0 },
        config: { duration: 350 },
        delay: $delay,
      };
      break;
    case "top-bottom":
      animationConfig = {
        from: { opacity: 0, y: -200 },
        to: { opacity: 1, y: 0 },
        config: { duration: 350 },
        delay: $delay,
      };
      break;
    case "bottom-top":
      animationConfig = {
        from: { opacity: 0, y: 200 },
        to: { opacity: 1, y: 0 },
        config: { duration: 350 },
        delay: $delay,
      };
      break;
    default:
      animationConfig = {
        from: { opacity: 0 },
        to: { opacity: 1 },
        config: { duration: 350 },
        delay: $delay,
      };
      break;
  }

  const styles = useSpring(
    $useInView ? (inView ? animationConfig : {}) : animationConfig
  );

  return [$useInView ? ref : null, styles];
}

export default useCustomInView;
