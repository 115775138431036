import { animated } from "react-spring";

import iconTop from "../components/Home/img/icon-top.webp";

import useCustomInView from "../components/index/useCustomInView";
import Search from "../components/Home/Search";
import Categories from "../components/Home/Categories";

function Home() {
  const [iconTopSpring, iconStyle] = useCustomInView('bottom-top');

  return (
    <div className="col-lg-10 col-home">
      <div className="row col-sm-11 col-lg-11 justify-content-center row-home">
        <animated.div className="icon-animated" style={iconStyle} ref={iconTopSpring}><img className="large-icon" src={iconTop} alt="Logo Onomatopeias" /></animated.div>
        <Search />
        <Categories />
      </div>
    </div>
  );
}

export default Home;
