import React, { useState, useEffect } from "react";
import { animated } from "react-spring";
import axios from "axios";
import { useTranslation } from "react-i18next";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import useCustomInView from "../index/useCustomInView";
import { faXmark } from "@fortawesome/free-solid-svg-icons";

function Search() {
  const [searchTerm, setSearchTerm] = useState("");
  const [suggestions, setSuggestions] = useState([]);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const { t, i18n } = useTranslation();

  const [searchWords, setSearchWords] = useState([]);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [currentWord, setCurrentWord] = useState('');
  const [typing, setTyping] = useState(true);

  useEffect(() => {
    const source = axios.CancelToken.source();
    const fetchSuggestions = async () => {
      try {
        setLoading(true);
        const response = await axios.get(
          `https://admin.onomatopeias.com/shortAPI?search=${encodeURIComponent(
            searchTerm
          )}`,
          { cancelToken: source.token }
        );

        if (response.status !== 200) {
          throw new Error("Error fetching suggestions. Please try again.");
        }

        const { onomatopeias } = response.data;
        if (onomatopeias.length === 0) {
          setError("No onomatopoeias found.");
        } else {
          setSuggestions(onomatopeias);
          setError(null);
        }
        setError(null);
      } catch (error) {
        if (axios.isCancel(error)) {
          console.log("Request canceled", error.message);
        } else {
          console.error("Error fetching suggestions:", error.message);
          setSuggestions([]);
          setError(
            error.message || "Error fetching suggestions. Please try again."
          );
        }
      } finally {
        setLoading(false);
      }
    };

    if (searchTerm.trim() === "") {
      setSuggestions([]);
      source.cancel("Operation canceled by the user.");
      return;
    }

    fetchSuggestions();
    return () => {
      source.cancel("Operation canceled by the user.");
    };
  }, [searchTerm]);

  const handleInputChange = (e) => {
    setSearchTerm(e.target.value);
  };

  useEffect(() => {
    // Atualiza searchWords sempre que o idioma mudar
    const words = Object.values(t('Home.Search', { returnObjects: true }));
    setSearchWords(words);
    setCurrentWord(words[0]); // Define a primeira palavra com base no idioma atual
  }, [t, i18n.language]); // Executa sempre que o idioma mudar

  useEffect(() => {
    const typingInterval = setInterval(() => {
      if (typing) {
        // Estamos digitando
        if (currentWord.length < searchWords[currentIndex].length) {
          setCurrentWord(
            searchWords[currentIndex].substring(0, currentWord.length + 1)
          );
        } else {
          setTyping(false); // Troca para apagar a palavra
        }
      } else {
        // Estamos apagando
        if (currentWord.length > 0) {
          setCurrentWord(currentWord.slice(0, -1));
        } else {
          setTyping(true); // Troca para digitar a próxima palavra
          const nextIndex = (currentIndex + 1) % searchWords.length;
          setCurrentIndex(nextIndex);
        }
      }
    }, 170); // Ajuste o intervalo conforme desejado (170 ms para um efeito mais rápido)

    return () => {
      clearInterval(typingInterval);
    };
  }, [currentIndex, currentWord, typing, searchWords]);

  const [search, searchStyles] = useCustomInView("right-left", 600);

  return (
    <animated.div style={searchStyles} ref={search} className="col-md-12">
      <form className="search-form">
        <input
          id="search"
          className={`${
            loading
              ? "search-input"
              : error
              ? "search-input error"
              : "search-input"
          } ${searchTerm.trim() !== "" ? "has-content" : ""}`}
          type="search"
          placeholder={"#" + currentWord}
          value={searchTerm}
          onChange={handleInputChange}
          autoComplete="off"
        />

        <FontAwesomeIcon icon={faXmark} />
        {suggestions.length > 0 && (
          <ul className="suggestions-list">
            {suggestions.map((onomatopeia) => (
              <li key={onomatopeia.id}>
                <div className="title">{onomatopeia.name}</div>
                <div className="tags">
                  {onomatopeia.tag_br.split(",").join(", ")}
                </div>
              </li>
            ))}
          </ul>
        )}
      </form>
    </animated.div>
  );
}

export default Search;
