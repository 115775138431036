import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCopy } from '@fortawesome/free-solid-svg-icons';
import useCustomInView from '../index/useCustomInView';
import { animated } from '@react-spring/web';

const OnomatopeiaItem = ({ category, onomatopeia, index, handleCopyClick, normalizeCategory }) => {
  const [ref, styles] = useCustomInView('right-left', index * 100, false);

  return (
    <animated.div ref={ref} style={styles} className="col-6 col-sm-4 col-lg-3">
      <div className={`${normalizeCategory(category)}`} onClick={(event) => handleCopyClick(event, onomatopeia.name)}>
        <div className="name">{onomatopeia.name}</div>
        <FontAwesomeIcon icon={faCopy} />
      </div>
    </animated.div>
  );
};

export default OnomatopeiaItem;
