import { animated } from "react-spring";

import useCustomInView from "../components/index/useCustomInView";

function About() {
  const [juwu, juwuStyle] = useCustomInView("right-left", 100, false);
  const [pandin, pandinStyle] = useCustomInView("left-right", 500, false);
  const [about, aboutStyle] = useCustomInView("bottom-top", 700, true)
  return (
    <div
      className="col-lg-10 col-about"
    >
      <div className="row">
        <animated.div ref={juwu} style={juwuStyle} className="juwu">
          <div className="text">
            Olá, eu sou a Juwu, idealizadora desse projeto e responsável pelo
            design
          </div>
          <div className="balloon"></div>
        </animated.div>
        <animated.div ref={pandin} style={pandinStyle} className="pandin">
          <div className="text">
            E eu sou o Pandin, desenvolvedor desse projeto e responsável pelo
            código
          </div>
          <div className="balloon"></div>
        </animated.div>

        <animated.div ref={about} style={aboutStyle} className="col-12 col-lg-10 about">
          Bem-vindos ao Onomatopeias, um espaço virtual dedicado a trazer o
          universo sonoro das suas criações à vida! Nosso site oferece uma
          coleção vibrante de expressões sonoras que elevam suas narrativas
          visuais a um novo patamar, agregando dinamismo e emoção às suas obras.
          Feito por criadores, para criadores, desejamos que cada "bam", "pow" e
          "splash" traga uma nova dimensão às suas criações de maneira única e
          cativante.
        </animated.div>
      </div>
    </div>
  );
}

export default About;
