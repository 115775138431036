import React, { useEffect, useState } from "react";
import axios from "axios";
import { motion } from "framer-motion";

const Tags = () => {
  const [tags, setTags] = useState([]);
  const [page, setPage] = useState(1);

  useEffect(() => {
    const interval = setInterval(() => {
      axios
        .get(`https://admin.onomatopeias.com/apitags?page=${page}`)
        .then((response) => {
          setTags((prevTags) => [...prevTags, ...response.data]);
          setPage((prevPage) => prevPage + 1);
        })
        .catch((error) => console.error(error));
    }, 500);

    return () => clearInterval(interval);
  }, [page]);

  const [selectedTag, setSelectedTag] = useState(null);

  const handleTagClick = (tag) => {
  };
  return (
    <div className="col-lg-10 custom">
      <div className="row col-sm-11 col-lg-11">
        {tags.map((tag, index) => (
          <motion.div
            key={index}
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 1 }}
            onClick={() => handleTagClick(tag)}
            className="tags"
          >
            #{tag.tag_br}
          </motion.div>
        ))}
      </div>
    </div>
  );
};

export default Tags;
